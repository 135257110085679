@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


.advantagesWrapper {
  background-image: url('../../../assets/img/advantagesBg.png'); /* Шлях до зображення */
  background-size: cover; /* Масштабує зображення, щоб воно заповнило весь контейнер */
  background-repeat: no-repeat; /* Забороняє повторення зображення */
  background-position: center; /* Центрує зображення у контейнері */
  //height: 100vh; /* Висота контейнера на всю висоту екрану */
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  min-height: 575px;
  height: 100%;
  //height: 575px;

  max-width: 1920px;
  width: 100%;
  //box-sizing: border-box;

  //padding: 0 20px;
  //max-width: 1280px;
  //width: 100%;
  .advantagesContainer {
    display: flex;
    z-index: 10;
    margin: 60px 0;
    width: 100%;
    padding: 0 20px;
    //padding: 0 20px;
    box-sizing: border-box;
    max-width: 1280px;

    flex-direction: column;
    //justify-content: center;
    gap: 60px;
    //align-items: center;
    //width: 100%;
    //justify-content: center;
    align-items: center;

    .header {

      font-family: "Archivo", sans-serif;

      color: $white;
      padding: 0;
      margin: 0;

      font-size: 36px; /* Розмір шрифту, налаштуйте відповідно до ваших потреб */
      font-weight: 1000; /* Жирний шрифт */
      text-transform: uppercase; /* Верхній регістр для всіх літер */
      text-align: center; /* Вирівнювання по центру */
      letter-spacing: 1px; /* Відстань між літерами */
      line-height: 1.2; /* Міжрядковий інтервал, налаштуйте відповідно */

    }

    .advantagesList {
      display: flex;
      gap: 69px;
      justify-content: center;
      align-items: flex-start;
      //align-items: center;

      @media (max-width: 1200px) {
        flex-direction: column;

        .card {
          flex: 1 1 auto;
          width: 100%;
        }
      }

      .card {
        flex: 1;
        padding: 24px;
        border: 1px solid #FFFFFF1A;
        backdrop-filter: blur(3px);
        background: rgba(255, 255, 255, 0.12);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        box-sizing: border-box;

        .description {
          font-size: 24px;
          font-weight: 300;
        }

        .cardHeader {
          display: flex;
          align-items: center;
          gap: 20px;

          img {
            background: radial-gradient(60.91% 60.91% at 50% 50%, rgba(255, 0, 0, 0.39) 0%, rgba(23, 23, 23, 0) 85%);
          }

          .headerText {
            color: $white;
            font-weight: 600;
            font-size: 24px;
            line-height: 31px;
            font-family: "Archivo", sans-serif;
          }
        }
      }
    }
  }
}
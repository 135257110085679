@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

@include white-button;

.footerWrapper {
  background: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
  box-sizing: border-box;
  width: 100%;

  .footerContainer {
    display: flex;
    //justify-content: space-between;
    max-width: 1280px;
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    gap: 20px;

    @media (max-width: 595px) {
      flex-direction: column;
    }

    svg {
      width: 95px;
    }

    .copyrightContainer {
      width: 100%;
      display: flex;
      gap: 48px;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 520px) {
        flex-direction: column;
        gap: 30px;
      }

      .copyrightText {
        p {
          margin: 0;
        }
      }

      .rightSideContainer {
        display: flex;
        gap: 5px;
        align-items: center;

        a {
          padding-top: 5px;
        }

        @media (max-width: 520px) {
          flex-direction: column;
          gap: 30px;

          a {
            padding-top: 0;
          }
        }

        .whiteBtn {
          width: 132px;
          height: 40px;
          font-size: 16px;
          border-radius: 16px;
        }
      }
    }
  }
}
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


@include white-button;

.formWrapper {
  background: $red;
  //position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 0 20px;
  max-width: 1920px;
  width: 100%;
  box-sizing: border-box;

  .formContainer {
    display: flex;
    z-index: 10;
    margin: 90px 0;

    padding: 0 20px;
    //padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1280px;

    flex-direction: column;
    gap: 40px;
    //align-items: center;
    //width: 100%;
    //justify-content: center;
    align-items: center;

    .header {
      text-align: center;
      font-size: 35px;
      font-weight: 1000;
      color: $white;
      font-family: "Archivo", sans-serif;
    }


    @media (max-width: 1280px) {
      .inputsWrapper {
        .inputsRow {
          flex-direction: column;

          .inputWrapper {
            width: 100%;
          }
        }
      }
    }


    .errorMessage {
      margin-top: 10px;
      color: $purple;
      font-size: 16px;
      font-weight: 700;
    }

    .whiteBtn {
      width: 252px;
    }

    .inputsWrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;
      .inputsRow {
        display: flex;
        align-items: flex-start;
        gap: 40px;

        .inputWrapper {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;

          .textInput {

            border: 1px solid #FBFBFB;
            //background: $theme;
            outline: none;
            padding: 16px 24px;
            box-sizing: border-box;
            //color: $new-white;
            font-size: 20px;

            //flex: 1 1 auto;
            background: #FBFBFB;
            height: 56px;
            border-radius: 20px;

            &::placeholder {
              //color: #CACFD8;
              color: #747272;
            }

            &.error {
              border: 2px solid $purple;
              color: $red;
            }
          }

          .errorMessage {
            margin-top: 10px;
            color: $purple;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins.scss";



* {
  font-family: 'Manrope', sans-serif;
}


body {
  margin: 0;
  height: 100%;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
  width: 100%;

  p {
    color: $grey-text;
  }

  background: $black;

  @font-face {
    font-family: "Druk Wide Cyr";
    src: url("../fonts/DrukTextWideCyr/DrukWideCyrBold.otf") format("otf"),
    url("../fonts/DrukTextWideCyr/DrukWideCyrBoldItalic.otf") format("otf"),
    url("../fonts/DrukTextWideCyr/DrukWideCyrHeavy.otf") format("otf"),
    url("../fonts/DrukTextWideCyr/DrukWideCyrHeavyItalic.otf") format("otf"),
    url("../fonts/DrukTextWideCyr/DrukWideCyrMedium.otf") format("otf"),
    url("../fonts/DrukTextWideCyr/DrukWideCyrMediumItalic.otf") format("otf"),
    url("../fonts/DrukTextWideCyr/DrukWideCyrSuper.otf") format("otf"),
    url("../fonts/DrukTextWideCyr/DrukWideCyrSuperItalic.otf") format("otf");
    font-weight: bold;
    font-style: normal;
  }

  &::-webkit-scrollbar {
    background: $white;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $red;
    //border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  scroll-behavior: smooth;



  //@font-face {
  //  font-family: "Druk Wide Cyr";
  //  src: url("../fonts/DrukTextWideCyr/DrukWideCyrBold.otf") format("otf"),
  //  url("../fonts/DrukTextWideCyr/DrukWideCyrBoldItalic.otf") format("otf"),
  //  url("../fonts/DrukTextWideCyr/DrukWideCyrHeavy.otf") format("otf"),
  //  url("../fonts/DrukTextWideCyr/DrukWideCyrHeavyItalic.otf") format("otf"),
  //  url("../fonts/DrukTextWideCyr/DrukWideCyrMedium.otf") format("otf"),
  //  url("../fonts/DrukTextWideCyr/DrukWideCyrMediumItalic.otf") format("otf"),
  //  url("../fonts/DrukTextWideCyr/DrukWideCyrSuper.otf") format("otf"),
  //  url("../fonts/DrukTextWideCyr/DrukWideCyrSuperItalic.otf") format("otf");
  //  font-weight: bold;
  //  font-style: normal;
  //}

  button {
    cursor: pointer;
    text-decoration: none;
    border: none;
    text-align: center;
    padding: 0;
    margin: 0;
    background: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  li {
    padding: 0;
    margin: 0;
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
    color: black;
  }
}
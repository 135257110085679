@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


.locationWrapper {
  background: $black;
  //position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 0 20px;

  max-width: 1920px;
  width: 100%;
  box-sizing: border-box;

  .locationContainer {
    .header {
      font-family: 'Archivo', sans-serif;
      //font-family: "Druk Wide Cyr", sans-serif;

      color: $white;

      font-size: 36px; /* Розмір шрифту, налаштуйте відповідно до ваших потреб */
      font-weight: 1000; /* Жирний шрифт */
      text-transform: uppercase; /* Верхній регістр для всіх літер */
      text-align: center; /* Вирівнювання по центру */
      letter-spacing: 1px; /* Відстань між літерами */
      line-height: 1.2; /* Міжрядковий інтервал, налаштуйте відповідно */

    }


    display: flex;
    z-index: 10;
    margin: 80px 0;

    padding: 0 20px;
    //padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1280px;
    flex-direction: column;
    gap: 40px;
    //align-items: center;
    //width: 100%;
    //justify-content: center;
    align-items: center;


    .contentContainer {
      display: flex;
      align-items: center;
      gap: 43px;

      @media (max-width: 1210px) {
        flex-direction: column;
        align-items: center;
      }

      .textContent {

        @media (max-width: 1210px) {
          display: flex;
          flex-direction: column;
          align-items: center;

          .text {
            max-width: 600px;
            width: 100%;
            text-align: center;
          }
        }
        
        .textHeader {
          margin: 0;
          font-weight: 700;
          font-size: 32px;
          font-family: "Archivo", sans-serif;

          @media (max-width: 435px) {
            text-align: center;
            font-size: 28px;
          }

          span {
            color: $red;
          }
        }

        .text {
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
        }
      }

      .locationImg {
        width: 685px;
        //width: 100%;

        @media (max-width: 765px) {
          width: 100%;
        }
      }
    }
  }
}
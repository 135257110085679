@import 'src/assets/styles/variables';

.headerWrapper {
  background: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 0 20px;
  max-width: 1920px;
  width: 100%;
  box-sizing: border-box;


  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 20px;
    box-sizing: border-box;
    max-width: 1280px;

    @media (max-width: 1280px) {
      flex-direction: column;
    }

    .navigationList {
      display: flex;
      gap: 48px;

      li button {
        padding: 7px 2px;
        font-size: 16px;
        color: $grey-text;
      }
    }
  }
}
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';


.heroWrapper {
  background: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 0 20px;
  max-width: 1920px;
  width: 100%;
  box-sizing: border-box;

  .heroContainer {
    margin-top: 50px;
    margin-bottom: 195px;
    display: flex;
    //justify-content: space-between;
    //align-items: center;

    padding: 0 20px;
    //padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1280px;
    position: relative;

    @media (max-width: 1280px) {
      margin-top: 30px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .textContent {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          margin: 40px 0;
          max-width: 550px;
          width: 100%;
        }

        .buttonsWrapper {
          flex-direction: column;
          gap: 30px !important;
        }
      }
    }

    //@media (max-width: 1310px) {
    //  flex-direction: column;
    //
    //  .textContent {
    //    p {
    //      max-width: 630px;
    //      width: 100%;
    //    }
    //  }
    //}

    .textContent {
      flex-grow: 1; /* Дозволяє кожному елементу займати рівний простір */
      flex-basis: 0;
      z-index: 10;

      @media (max-width: 1390px) {
        img {
          width: 500px !important;
        }
      }

      @media (max-width: 575px) {
        img {
          width: 400px !important;
        }
      }

      @media (max-width: 480px) {
        img {
          width: 330px !important;
        }
      }

      @media (max-width: 400px) {
        img {
          width: 280px !important;
        }
      }

      p {
        font-size: 28px;
        text-align: center;
      }

      .buttonsWrapper {
        display: flex;
        justify-content: space-evenly;
        gap: 60px;

        @include white-button;
        @include red-button;

        .whiteBtn,
        .redBtn {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .iphonesImgContainer {
      position: relative;
      flex-grow: 1; /* Дозволяє кожному елементу займати рівний простір */
      flex-basis: 0;
      //width: 650px;
      width: 750px;
      display: flex;
      justify-content: flex-end;

      //@media (max-width: 1400px) {
      //  width: 650px !important;
      //  .iphonesImg {
      //    width: 650px !important;
      //    left: 0 !important;
      //  }
      //}

      @media (max-width: 1390px) {

        //width: 550px !important;
        width: 100%;
      }

      .iphonesImg {
        pointer-events: none;
        //width: 650px;
        width: 750px;
        //width: 100%;
        left: -100px;
        top: -20px;
        position: absolute;

        @media (max-width: 1390px) {

          //width: 550px !important;

          width: 550px !important;
          //left: 0 !important;
          //right: 20px !important;
          left: auto;
          top: 10px !important;
          right: 0 !important;
        }

        @media (max-width: 1280px) {
          left: 50% !important;
          right: 50% !important;
          transform: translate(-50%, -12%) !important;
        }

        @media (max-width: 575px) {
          width: 250px !important;
        }

      }
    }
  }
}
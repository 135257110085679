@import 'src/assets/styles/variables';

@mixin white-button {
    .whiteBtn {
      color: $red;
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: #f0f0f0;
      box-shadow: 2px 4px 5px 0 rgba(0, 0, 0, 0.25);
      height: 66px;
      width: 220px;
      box-sizing: border-box;
      border-radius: 20px;
      font-weight: 600;
      font-size: 24px;
      transition: .1s background ease-in-out;
      white-space: nowrap;

      &:hover {
        background: #FFCCCC;
        transition: .1s background ease-in-out;
      }
    }
}

@mixin red-button {
  .redBtn {
    color: $white;
    border: 1px solid #0000001A;
    background: #ff0000;
    box-shadow: 2px 4px 5px 0 #00000040;
    height: 66px;
    width: 220px;
    box-sizing: border-box;
    border-radius: 20px;
    font-weight: 600;
    font-size: 24px;
    transition: .1s background ease-in-out;

    &:hover {
      background: #9E0101;
      transition: .1s background ease-in-out;
    }
  }
}